<template>
  <div v-if="focusKnowledge" class="d-flex align-items-center">
    <BaseDropdownOptionsWrapper
      :options="dropdownOptions"
      iconOnly
      isHorizontal
      @select="handleSelectOption"
    />
    <ModalEvents
      :uid="deleteModalUniqueKey"
      modal-name="DeleteContentModal"
      @choice="archiveContent"
    />
    <ModalEvents
      :uid="deleteModalUniqueKey"
      modal-name="SoftDeleteContentModal"
      @choice="softDeleteContent"
    />
    <ModalEvents
      :uid="createTemplateUniqueKey"
      modal-name="CreateTemplateModal"
      @choice="handleCreateTemplate"
    />
    <ModalEvents
      :uid="deleteStagedVersionModalUniqueKey"
      modal-name="DeleteStagedVersionModal"
      @choice="$emit('delete-staged-version')"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {getDeskTranslatedEntityUrl} from '@/utils/adminToDeskRouteMapping';
import ModalEvents from '@/components/Modals/ModalEvents';
import BaseDropdownOptionsWrapper from '../../BaseDropdownOptionsWrapper.vue';

export default {
  name: 'case-header-options',
  components: {
    ModalEvents,
    BaseDropdownOptionsWrapper,
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        pdfExport: false,
        contentSettings: false,
        copyToClipboard: false,
        deleteCase: false,
        cloneCase: false,
        deleteContent: false,
        versionContent: false,
        integrationImport: false,
        settings: false,
        createTemplate: false,
        createStagedVersion: false,
      }),
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    exportToast: {
      label: null,
      type: null,
    },
  }),
  computed: {
    dropdownOptions() {
      const computedOptions = [];

      if (this.options.pdfExport) {
        computedOptions.push({
          key: 'EXPORT_PDF',
          label: this.$t('knowledge.actions.export.pdf'),
          icon: ['fal', 'file-export'],
        });
      }
      if (this.options.integrationImport) {
        computedOptions.push({
          key: 'IMPORT',
          label: this.$t('knowledge.actions.import-article'),
          icon: ['fal', 'download'],
          disabled: Boolean(!this.userPermissions.canUpdateContent),
        });
      }

      if (this.options.contentSettings) {
        computedOptions.push({
          key: 'CONTENT_SETTINGS',
          label: this.options.isSettingsDrawerOpen
            ? this.$t('knowledge.actions.content-settings.close')
            : this.$t('knowledge.actions.content-settings.open'),
          icon: ['fal', 'cog'],
        });
      }

      if (this.options.copyToClipboard) {
        computedOptions.push({
          key: 'COPY_TO_CLIPBOARD',
          label: this.$t('knowledge.actions.copy-to-clipboard'),
          icon: ['fal', 'clipboard'],
        });
      }

      if (this.options.cloneCase) {
        computedOptions.push({
          key: 'CLONE_CASE',
          label: this.$t('knowledge.actions.clone-in-other-kb'),
          icon: ['fal', 'clone'],
          disabled: Boolean(!this.userPermissions.canCreateContent),
        });
      }

      if (this.options.editIcons) {
        computedOptions.push({
          key: 'EDIT_ICON',
          label: this.$t('knowledge.actions.change-icon'),
          icon: ['fal', 'image-polaroid'],
          disabled: Boolean(!this.userPermissions.canUpdateContent),
        });

        computedOptions.push({
          key: 'EDIT_MINI_ICON',
          label: this.$t('knowledge.actions.change-mini-icon'),
          icon: ['fal', 'image-polaroid'],
          disabled: Boolean(!this.userPermissions.canUpdateContent),
        });
      }

      if (this.options.deleteCase) {
        computedOptions.push({
          key: 'DELETE_CASE',
          label: this.$t('knowledge.actions.delete'),
          icon: ['fal', 'archive'],
          disabled: Boolean(!this.userPermissions.canDeleteContent),
          divider: !this.isParametric,
          type: 'danger',
        });

        computedOptions.push({
          key: 'SOFT_DELETE_CASE',
          label: this.$t('knowledge.actions.soft-delete'),
          icon: ['fal', 'trash'],
          disabled: Boolean(!this.userPermissions.canDeleteContent),
          type: 'danger',
        });
      }

      if (this.options.versionContent) {
        computedOptions.push({
          key: 'VERSION_CONTENT',
          label: this.$t('knowledge.actions.display-versions'),
          icon: ['fal', 'history'],
        });
      }

      if (this.options.createTemplate) {
        computedOptions.push({
          key: 'CREATE_TEMPLATE',
          label: this.$t('knowledge.actions.create-template'),
          icon: ['fal', 'file'],
          disabled: Boolean(!this.userPermissions.canCreateContent),
        });
      }

      if (this.options.createStagedVersion) {
        computedOptions.push({
          key: 'CREATE_STAGED_VERSION',
          label: this.$t('knowledge.staged-version.create'),
          icon: ['fal', 'file-alt'],
          disabled: Boolean(!this.userPermissions.canUpdateContent),
        });
      }

      if (this.options.archiveContent) {
        computedOptions.push({
          key: 'ARCHIVE_CONTENT',
          label: this.$t('knowledge.actions.archive'),
          icon: ['fal', 'archive'],
          disabled: Boolean(!this.userPermissions.canDeleteContent),
          divider: !this.isParametric,
          type: 'danger',
        });
      }

      if (this.options.deleteContent || this.options.deleteStagedVersion) {
        computedOptions.push({
          key: 'DELETE_CONTENT',
          label: this.options.deleteStagedVersion
            ? this.$t('knowledge.actions.delete-staged-version')
            : this.$t('knowledge.actions.soft-delete'),
          icon: ['fal', 'trash'],
          disabled: Boolean(!this.userPermissions.canDeleteContent),
          type: 'danger',
        });
      }

      return computedOptions;
    },
    deleteModalUniqueKey() {
      return `${this.$options.name}-${this.focusKnowledge.id}`;
    },
    deleteStagedVersionModalUniqueKey() {
      return `${this.$options.name}-staged-${this.focusKnowledge.id}`;
    },
    createTemplateUniqueKey() {
      return `${this.$options.name}-template-${this.focusKnowledge.id}`;
    },
    ...mapGetters(['isParametric']),
    ...mapGetters('adminModule', ['companyHostname']),
    ...mapGetters('knowledgeModule', [
      'focusKnowledge',
      'focusContent',
      'focusKnowledgeIsPublic',
      'editingLanguage',
      'focusKnowledgeIsMultilinguale',
    ]),
  },
  methods: {
    handleSelectOption(e) {
      const key = e.detail[0];

      if (!key) return;

      if (key === 'IMPORT') {
        return this.$emit('open-integration-modal');
      } else if (key === 'CONTENT_SETTINGS') {
        return this.$emit(
          'open-content-settings',
          !this.options.isSettingsDrawerOpen,
        );
      } else if (key === 'COPY_TO_CLIPBOARD') {
        return this.copyUrl();
      } else if (key === 'CLONE_CASE') {
        return this.$emit('open-clone-modal');
      } else if (key === 'EDIT_ICON') {
        return this.$emit('update-icon');
      } else if (key === 'EDIT_MINI_ICON') {
        return this.$emit('update-mini-icon');
      } else if (key === 'DELETE_CASE') {
        return this.$emit('open-delete-modal');
      } else if (key === 'SOFT_DELETE_CASE') {
        return this.$emit('open-delete-modal', {softDeleted: true});
      } else if (key === 'VERSION_CONTENT') {
        return this.$emit('open-content-version');
      } else if (key === 'CREATE_TEMPLATE') {
        return this.openCreateTemplate();
      } else if (key === 'CREATE_STAGED_VERSION') {
        return this.$emit('create-staged-version');
      } else if (key === 'ARCHIVE_CONTENT') {
        return this.openDeleteContent();
      } else if (key === 'DELETE_CONTENT') {
        return this.handleDeleteContent(this.userPermissions.canDeleteContent);
      } else if (key === 'EXPORT_PDF') {
        return this.handleExportPDF();
      }
    },
    async copyUrl() {
      try {
        const {query, hash, params} = this.$route;

        const url = getDeskTranslatedEntityUrl({
          entityId: params.stepId || params.contentId || params.caseId,
          entityType: params.caseId ? 'Case' : 'Content',
          lang: this.editingLanguage,
          hash,
          query,
          knowledgeId: this.focusKnowledge.id,
        });

        await navigator.clipboard.writeText(window.location.origin + url);

        this.$message({
          message: this.$t(
            'knowledge.actions.copy-to-clipboard-confirmation-message',
          ),
          type: 'info',
        });
      } catch (e) {
        this.$message({
          message: this.$t('knowledge.actions.copy-to-clipboard-error-message'),
          type: 'error',
        });
      }
    },
    handleDeleteContent(hasPermissions) {
      if (!hasPermissions) return;
      if (this.options.deleteContent)
        return this.openDeleteContent({softDeleted: true});
      this.openDeleteStagedVersion();
    },
    async openDeleteContent({softDeleted} = {}) {
      const contentId = this.focusContent.id;

      const {relatedCount, automationsCount} =
        await this.countLinkedReferences({
          contentId,
        });

      const payload = {
        component: softDeleted
          ? 'SoftDeleteContentModal'
          : 'DeleteContentModal',
        uid: this.deleteModalUniqueKey,
        props: softDeleted
          ? {
            display: true,
            isCase: this.isCase,
            relatedCount,
            automationsCount,
            label: this.focusContent.label,
          }
          : {
            display: true,
            id: contentId,
            relatedCount,
            automationsCount,
            label: this.focusContent.label,
          },
      };
      this.openModal(payload);
    },
    openDeleteStagedVersion() {
      const payload = {
        component: 'DeleteStagedVersionModal',
        uid: this.deleteStagedVersionModalUniqueKey,
        props: {
          display: true,
        },
      };
      this.openModal(payload);
    },
    async openCreateTemplate() {
      const payload = {
        component: 'CreateTemplateModal',
        uid: this.createTemplateUniqueKey,
        props: {display: true, name: this.focusContent.label},
      };
      this.openModal(payload);
    },
    async handleCreateTemplate({name, description}) {
      const template = {
        name,
        description,
        contentId: this.focusContent.id,
      };

      let loadingMessage = this.$message({
        duration: 0,
        dangerouslyUseHTMLString: true,
        iconClass: 'display: none',
        message: `<i class="fas fa-spinner fa-spin" style="margin-right: 1em;"></i> ${this.$t(
          'knowledge.templates.create.loading',
        )}`,
      });

      try {
        const res = await this.createTemplateByContentId(template);

        if (!res) throw new Error('TEMPLATE_FAILED');
        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.templates.create.succeed'),
          type: 'success',
        });
        this.cover = false;
      } catch (e) {
        loadingMessage.close();
        this.$message({
          message: this.$t('knowledge.templates.create.failed'),
          type: 'error',
        });
      }
    },
    archiveContent(choice) {
      if (choice) this.$emit('archive');
    },
    softDeleteContent(choice) {
      if (choice)
        return this.$emit('archive', {
          contentIds: [this.focusContent.id],
          softDeleted: true,
        });
    },
    async handleExportPDF() {
      await this.getArticlePDFExport(
        {
          contentId: this.focusContent.id,
          lang: this.editingLanguage,
          contentLabel: this.focusContent.label,
        });
    },
    ...mapActions('modalsModule', ['openModal']),
    ...mapActions('knowledgeModule', [
      'countLinkedReferences',
      'createTemplateByContentId',
    ]),
    ...mapActions('kbExportModule', ['getArticlePDFExport']),
  },
};
</script>

<style lang='scss' scoped></style>
