<template>
  <div class="archive-banner-wrapper">
    <font-awesome-icon
      :icon="['fas', 'times-circle']"
      class="archive-banner-wrapper-icon"
    />

    <div class="archive-banner" v-if="archiveError">
      <span>
        {{ $t(`${i18nPath}.error.text`) }}
      </span>
      <span class="ml-4">
        <base-button
          variant="secondary"
          :text="$t(`${i18nPath}.error.cta`)"
          @click="goToArchives"
        >
        </base-button>
      </span>
    </div>
    <div class="archive-banner" v-else>
      <span>
        {{
          $t(`${i18nPath}.${currentFocus}.text1`, {
            collection: mainCaseArchiveLabel,
          })
        }}
      </span>
      <span v-if="deletionDate">
        {{ $t(`${i18nPath}.${currentFocus}.text2`, { date: deletionDate }) }}
      </span>
      <div class="actions-container">
        <not-allowed-tooltip
          :user-permissions="focusKnowledge.userPermissions"
          permission-key="canCreateContent"
          placement="bottom"
        >
          <template v-slot:main-content="{ hasPermissions }">
            <el-tooltip
              :content="$t(`${i18nPath}.content.steps-no-ancestor`)"
              placement="bottom"
              :disabled="archiveFormerMainAncestor || !hasPermissions"
            >
              <base-button
                variant="secondary"
                @click="hasPermissions ? beforeRestoreArchive() : ''"
                :loading="loading.restore"
                :disabled="!hasPermissions || !archiveFormerMainAncestor"
                :text="$t(`${i18nPath}.${currentFocus}.actions.restore`)"
              >
              </base-button>
            </el-tooltip>
          </template>
        </not-allowed-tooltip>

        <not-allowed-tooltip
          :user-permissions="focusKnowledge.userPermissions"
          permission-key="canDeleteContent"
          placement="bottom"
        >
          <template v-slot:main-content="{ hasPermissions }">
            <base-button
              variant="secondary"
              @click="hasPermissions ? deleteArchive() : ''"
              :loading="loading.restore"
              :disabled="!hasPermissions"
              :text="$t(`${i18nPath}.${currentFocus}.actions.delete`)"
            >
            </base-button>
          </template>
        </not-allowed-tooltip>
      </div>
    </div>
    <CasesSelectionModal
      v-if="selectCases"
      :display="selectCases"
      :knowledge="focusArchiveKnowledge"
      :multi-select="currentFocus === 'content'"
      @choice="restoreArchive"
      @close="selectCases = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CasesSelectionModal from '@/components/Modals/CasesSelectionModal/CasesSelectionModal';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'ArchiveViewerHeader',
  components: {
    CasesSelectionModal,
    NotAllowedTooltip,
  },
  computed: {
    currentFocus() {
      return this.$route.params.caseArchiveId ? 'case' : 'content';
    },
    focusArchiveKnowledge() {
      return this.currentFocus === 'case'
        ? this.mainCaseArchiveKnowledge
        : this.archiveContentKnowledge;
    },
    deletionDate() {
      return this.currentFocus === 'case'
        ? this.mainCaseArchiveDeletionDate
        : this.archiveDeletionDate;
    },
    focusRequiresCaseSelection() {
      return this.currentFocus === 'case'
        ? this.mainCaseArchiveHasFormerCaseParents
        : this.archiveHasFormerCaseParents;
    },
    ...mapGetters('knowledgeModule', [
      'archiveContent',
      'archiveContentKnowledge',
      'archiveDeletionDate',
      'archiveHasFormerCaseParents',
      'archiveFormerMainAncestor',
      'archiveError',
      'mainCaseArchiveHasFormerCaseParents',
      'mainCaseArchiveKnowledge',
      'mainCaseArchiveLabel',
      'mainCaseArchiveDeletionDate',
      'focusKnowledge',
      'focusKnowledgeId',
      'editingLanguage',
    ]),
  },
  data() {
    return {
      loading: {
        restore: null,
        delete: null,
      },
      selectCases: false,
      i18nPath: 'knowledge.archives.top-banner',
    };
  },
  methods: {
    async beforeRestoreArchive() {
      if (this.focusRequiresCaseSelection) {
        return this.restoreArchive();
      } else {
        this.selectCases = true;
      }
    },
    async restoreArchive(caseParameters = null) {
      this.selectCases = false;
      this.loading.restore = 'fas fa-spinner fa-spin';

      try {
        if (this.currentFocus === 'content') {
          await this.restoreFocusArchive(caseParameters);
        } else if (this.currentFocus === 'case') {
          let parent = caseParameters ? caseParameters[0] : null;
          parent = parent.split('/').slice(-1)[0];
          await this.restoreMainCaseArchive(parent);
        }
      } catch (err) {
        return err;
      } finally {
        this.loading.restore = null;
        this.goToArchives();
      }
    },
    async deleteArchive() {
      this.loading.delete = 'fas fa-spinner fa-spin';

      if (this.currentFocus === 'content') {
        await this.deleteFocusArchive();
      } else if (this.currentFocus === 'case') {
        await this.deleteCaseArchive();
      }

      this.goToArchives();
    },
    goToArchives() {
      window.location.href = `/archives/${this.editingLanguage}/${this.focusKnowledgeId}`;
    },
    ...mapActions('knowledgeModule', [
      'restoreFocusArchive',
      'deleteFocusArchive',
      'restoreMainCaseArchive',
      'deleteCaseArchive',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.archive-banner-wrapper {
  position: sticky;
  top: 0;
  background-color: $red-1-mayday;
  color: $grey-9-mayday;
  padding: 6px 12px;
  border-top-left-radius: 16px;
  display: flex;
  align-items: center;
}

.archive-banner-wrapper-icon {
  color: $red-mayday;
  margin-right: 6px;
}

.archive-banner {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions-container {
  display: inline-flex;
  gap: 8px;
  margin-left: 24px;
}
</style>
