<template>
  <div
    ref="settings-list"
    class="public-knowledge-settings-list"
    @scroll="handleScroll"
  >
    <div
      v-for="currentMenuItem in items"
      :key="currentMenuItem.name"
      :ref="currentMenuItem.name"
    >
      <div
        v-for="subItem in currentMenuItem.children"
        :key="subItem.title"
        :id="`public-settings-item_${
          currentMenuItem.name
        }_${subItem.hash.substring(1)}`"
        :ref="subItem.hash"
      >
        <div class="d-flex flex-row align-items-center subItem-header">
          <div
            :class="['icon-wrapper', { active: activeRoute === subItem.hash }]"
          >
            <font-awesome-icon :icon="subItem.icon" class="subItem-icon" />
          </div>

          <span class="subItem-title">{{ $t(subItem.title) }} </span>
        </div>

        <div v-if="subItem.changingElements" class="subItem">
          <div v-for="element in subItem.changingElements" :key="element.label">
            <PublicKnowledgeElement
              v-if="checkElementFeatureFlags(element.featureFlag)"
              :element="element"
              class="mb-3"
              @new-operation="$emit('new-operation', $event)"
              @new-language="$emit('new-language', $event)"
              @set-multilingual="$emit('set-multilingual', $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicKnowledgeElement from './PublicKnowledgeElement.vue';
import { mapGetters } from 'vuex';
const HASH_FALLBACK = {
  appearances: 'brand',
  configuration: 'language',
  marketplace: 'catalogue',
};

export default {
  name: 'PublicKnowledgeMenuItem',
  data() {
    return {
      activeRoute: null,
      focus: false,
      scroll: false,
    };
  },
  props: {
    items: Array,
  },
  components: { PublicKnowledgeElement },
  computed: {
    ...mapGetters(['hasCompanyPreferenceWithValue']),
  },
  methods: {
    focusSetting(route) {
      if (this.scroll) return;
      const { path, hash } = route;
      const setting = path.split('/').pop();
      const element = this.$refs[hash];
      this.activeRoute = hash;
      if (!element || !element.length) {
        if (hash) return;
        this.$router.push({ hash: HASH_FALLBACK[setting] || '' });
        return;
      }
      this.focus = true;
      this.$refs['settings-list'].scrollTo({
        top: element[0].offsetTop - 180,
        behavior: 'smooth',
      });
      setTimeout(() => {
        this.focus = false;
      }, 1000);
    },
    handleScroll() {
      if (this.focus) return;
      const top = this.$refs['settings-list'].scrollTop + 180;
      const items = this.getItemsDiv();

      let idx = items.findIndex(
        ({ offsetTop, offsetBottom }) =>
          offsetTop <= top && top <= offsetBottom,
      );
      if (idx < 0) return;

      const [, newName, newId] = items[idx].id.split('_');

      const newHash = '#' + newId;
      const { hash } = this.$route;
      if (hash === newHash) return;

      this.scroll = true;
      this.activeRoute = newHash;
      this.$router.push({ name: `public-${newName}`, hash: newHash });
      setTimeout(() => {
        this.scroll = false;
      }, 500);
    },
    getItemsDiv() {
      const itemsEl = document.querySelectorAll(
        `[id^="public-settings-item_"]`,
      );
      return [...itemsEl].map(({ offsetTop, id, offsetHeight }) => ({
        offsetTop,
        id,
        offsetBottom: offsetTop + offsetHeight,
      }));
    },
    checkElementFeatureFlags(featureFlag) {
      if (!featureFlag) return true;
      return this.hasCompanyPreferenceWithValue(featureFlag);
    },
  },

  watch: {
    $route: {
      handler(newVal) {
        this.focusSetting(newVal);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.public-knowledge-settings-list {
  width:100%;
  flex: 1;
  overflow-y: auto;
}
.subItem {
  background-color: white;
  border: 1px $grey-4-mayday solid;
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 25px;
  min-width: 600px;
}

.subItem-header {
  margin-bottom: 10px;
}

.icon-wrapper {
  width: 28px;
  height: 28px;
  background-color: $grey-2-mayday;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.active {
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
}

.subItem-icon {
  width: 14px;
  height: 14px;
}

.subItem-title {
  font-family: Lato;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  text-align: left;
  font-weight: 600;
}
</style>
