import { render, staticRenderFns } from "./ParametricContentArchiveBackButton.vue?vue&type=template&id=489087be&scoped=true&"
import script from "./ParametricContentArchiveBackButton.vue?vue&type=script&lang=js&"
export * from "./ParametricContentArchiveBackButton.vue?vue&type=script&lang=js&"
import style0 from "./ParametricContentArchiveBackButton.vue?vue&type=style&index=0&id=489087be&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "489087be",
  null
  
)

export default component.exports