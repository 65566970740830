<template>
  <div>
    <div class="users-invitations-row">
      <!-- EMAIL -->
      <div class="users-invitations-email-wrapper">
        <div class="users-invitations-label">
          {{ $t('settings.permissions.user-invite-modal.form.email') }}
          <!-- EMAIL ERROR -->
          <el-tooltip
            class="ml-1 text-error"
            v-if="emailError"
            :content="emailTooltipErrors"
            placement="top"
          >
            <font-awesome-icon :icon="['fas', 'info-circle']" />
          </el-tooltip>
        </div>
        <!-- EMAIL INPUT -->
        <base-input
          class="w-full"
          :model-value="localEmail"
          @update:model-value="localEmail = $event.detail[0]"
          :placeholder="$t('settings.permissions.user-invite-modal.form.email')"
          :error="emailError || forceError"
        />
        <!-- MULTIPLE EMAILs -->
        <div v-if="isMultipleEmail" class="multiple-emails">
          <div
            v-for="(email, index) in emails"
            :key="index"
            :class="{
              'text-error': emailErrors[index] || validityErrors[index],
            }"
          >
            {{ email ? email : '...' }}
          </div>
        </div>
      </div>
      <!-- GROUPS ROLEs LABELS -->
      <div class="users-invitations-permission">
        <!-- GROUPS -->
        <div class="users-invitations-input-wrapper">
          <div class="users-invitations-label">
            {{ $t('settings.permissions.user-invite-modal.form.groups') }}
            <el-tooltip
              class="ml-1 text-error"
              v-if="errors.groups"
              :content="
                $t('settings.permissions.user-invite-modal.errors.groups')
              "
              placement="top"
            >
              <font-awesome-icon :icon="['fas', 'info-circle']" />
            </el-tooltip>
          </div>
          <el-select
            collapse-tags
            size="small"
            v-model="localGroupId"
            :placeholder="
              $t('settings.permissions.user-invite-modal.form.groups')
            "
            class="users-invitations-input"
            :class="{ error: errors.groups }"
            @change="handleChange('groups')"
          >
            <el-option
              v-for="group in groups"
              :key="group.id"
              :value="group.id"
              :label="group.name"
            ></el-option>
          </el-select>
        </div>
        <!-- ROLES -->
        <div class="users-invitations-input-wrapper">
          <div class="users-invitations-label">
            {{ $t('settings.permissions.user-invite-modal.form.roles') }}

            <el-tooltip
              class="ml-1 text-error"
              v-if="errors.roles"
              :content="
                $t('settings.permissions.user-invite-modal.errors.roles')
              "
              placement="top"
            >
              <font-awesome-icon :icon="['fas', 'info-circle']" />
            </el-tooltip>
          </div>
          <el-select
            multiple
            collapse-tags
            size="small"
            v-model="localRoleIds"
            :placeholder="
              $t('settings.permissions.user-invite-modal.form.roles')
            "
            :class="{ error: errors.roles, multiple: localRoleIds.length > 1 }"
            class="users-invitations-input"
            @change="handleChange('roles')"
          >
            <el-option
              v-for="role in roles"
              :key="role.id"
              :value="role.id"
              :label="role.name"
            ></el-option>
          </el-select>
        </div>
        <!-- LABELS -->
        <div class="users-invitations-input-wrapper">
          <div class="users-invitations-label">
            {{ $t('settings.permissions.user-invite-modal.form.labels') }}
          </div>
          <el-select
            multiple
            collapse-tags
            size="small"
            v-model="localLabelIds"
            :placeholder="
              $t('settings.permissions.user-invite-modal.form.labels')
            "
            class="users-invitations-input"
            :class="{ multiple: localLabelIds.length > 1 }"
            @change="handleChange('labels')"
          >
            <el-option-group
              v-for="category in labelCategories"
              :key="category.id"
            >
              <div class="select-category">
                <font-awesome-icon
                  :icon="['fad', category.icon]"
                  class="mr-2"
                  :style="{ color: category.color }"
                />
                {{ category.name }}
              </div>
              <div
                v-if="!category.companyUserLabels.length"
                class="empty-category"
              >
                {{
                  $t('settings.permissions.user-invite-modal.form.empty-labels')
                }}
              </div>
              <el-option
                v-for="label in category.companyUserLabels"
                :key="label.id"
                :label="label.name"
                :value="label.id"
              >
                {{ label.name }}
              </el-option>
            </el-option-group>
          </el-select>
        </div>
      </div>

      <!-- CTA -->
      <el-tooltip :content="$t('generic.delete')">
        <base-button
          variant="tertiary"
          size="md"
          :text="$t('settings.permissions.user-invite-modal.delete-button')"
          @click="$emit('delete')"
        ></base-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isValidEmail } from 'utils/isValidEmail';

export default {
  name: 'users-invitations-row',
  props: {
    user: {
      type: Object,
      default: () => ({
        tempId: '0',
        email: '',
        groupIds: [],
        roleIds: [],
      }),
    },
    forceError: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    labelCategories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      localEmail: this.user.email,
      localGroupId: this.user.groupIds[0],
      localRoleIds: this.user.roleIds,
      localLabelIds: this.user.labelIds,
      limit: 50,
      errors: {
        email: false,
        validity: false,
        groups: false,
        roles: false,
      },
      // multiple emails errors
      emailErrors: [false],
      validityErrors: [false],
    };
  },
  computed: {
    emails() {
      return this.localEmail.split(',').map((email) => email.trim());
    },
    isMultipleEmail() {
      return this.emails.length > 1;
    },
    isValid() {
      return Object.keys(this.errors)
        .map((key) => !this.errors[key])
        .every(Boolean);
    },
    isDuplicate() {
      if (!this.isMultipleEmail) return false;

      const duplicate = this.emails.filter(
        (item, index) => this.emails.indexOf(item) != index,
      );
      return !!duplicate.length;
    },
    emailError() {
      return (
        this.errors.email ||
        this.errors.validity ||
        this.isLimit ||
        this.isDuplicate
      );
    },
    emailTooltipErrors() {
      let errors = [
        ...(this.errors.email
          ? [this.$t('settings.permissions.user-invite-modal.errors.email')]
          : []),
        ...(this.errors.validity
          ? [this.$t('settings.permissions.user-invite-modal.errors.validity')]
          : []),
        ...(this.isLimit
          ? [this.$t('settings.permissions.user-invite-modal.errors.limit')]
          : []),
        ...(this.isDuplicate
          ? [this.$t('settings.permissions.user-invite-modal.errors.duplicate')]
          : []),
      ];

      return errors.join('\n');
    },
    isLimit() {
      if (!this.isMultipleEmail) return false;

      return this.emails.length > this.limit;
    },
    localUser() {
      return {
        tempId: this.user.tempId,
        email: this.localEmail.trim(),
        groupIds: [this.localGroupId],
        roleIds: this.localRoleIds,
        labelIds: this.localLabelIds,
      };
    },
    disableEmailValidation() {
      return this.hasCompanyPreferenceWithValue('ALLOW_NO_EMAIL_USERS');
    },
    labelToCategoryLookup() {
      const lookup = new Map();
      this.userLabelCategories.forEach((category) => {
        category.companyUserLabels.forEach((label) => {
          lookup.set(label.id, category.id);
        });
      });
      return lookup;
    },
    ...mapGetters(['hasCompanyPreferenceWithValue']),
    ...mapGetters('adminModule', ['userLabelCategories']),
  },
  mounted() {
    if (!this.localGroupId) {
      this.localGroupId = this.groups[0].id;
    }
    if (!this.localRoleIds.length) {
      this.localRoleIds = [this.roles[0].id];
    }
    this.handleChange(null);
  },
  methods: {
    async checkUser(type) {
      // one role
      if (type === null || type === 'roles')
        this.errors.roles = this.localRoleIds.length < 1;

      // one group
      if (type === null || type === 'groups')
        this.errors.groups = !this.localGroupId;

      if (type === null || type === 'email')
        if (!this.isMultipleEmail) {
          // is valid email
          this.errors.email = !this.checkEmail(this.localEmail);
          // email validity
          this.errors.validity = this.errors.email
            ? false
            : !(await this.checkEmailValidity(this.localEmail));
        } else {
          await this.checkEmails();
        }
    },
    checkEmail(email) {
      if (!email || email.length < 1) return false;
      if (this.disableEmailValidation) return true;
      return isValidEmail(email);
    },
    async checkEmails() {
      this.emailErrors = this.emails.map((email) => !this.checkEmail(email));
      this.validityErrors = await Promise.all(
        this.emails.map(
          async (email) => !(await this.checkEmailValidity(email, true)),
        ),
      );

      this.errors.email = this.emailErrors.findIndex((e) => e) > -1;
      this.errors.validity = this.validityErrors.findIndex((e) => e) > -1;
    },
    async checkEmailValidity(email, skipMessage = false) {
      this.loading = true;
      const res = await this.checkUserValidity(email);
      if (!res && !skipMessage) {
        this.$message({
          message: this.$t(
            'settings.permissions.user-invite-modal.errors.validity',
          ),
          type: 'error',
          center: true,
        });
      }
      this.loading = false;

      return res;
    },
    async handleChange(type) {
      if (type === 'labels')
        this.localLabelIds = [
          ...this.uniqueValuePerCategory(this.localLabelIds).values(),
        ];
      await this.checkUser(type);
      this.$emit('change', this.localUser);
    },
    uniqueValuePerCategory(newSelectedValues) {
      const lookup = new Map();
      newSelectedValues.forEach((labelId) => {
        lookup.set(this.labelToCategoryLookup.get(labelId), labelId);
      });
      return lookup;
    },
    ...mapActions('adminModule', ['checkUserValidity']),
  },
  watch: {
    isDuplicate(newVal) {
      if (newVal) {
        this.localValid = false;
        return this.$emit('valid', this.localValid);
      }
    },
    isLimit(newVal) {
      if (newVal) {
        this.localValid = false;
        return this.$emit('valid', this.localValid);
      }
    },
    localEmail() {
      this.handleChange('email');
    },
  },
};
</script>

<style lang="scss" scoped>
.users-invitations-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0px 32px;
  width: 100%;
  margin-bottom: 8px;
  align-items: end;
}
.users-invitations-permission {
  display: flex;
}

.users-invitations-email-wrapper {
  margin-right: 16px;
  min-width: 300px;
}

.users-invitations-input-wrapper {
  margin-right: 16px;
  min-width: 200px;
}

.users-invitations-label {
  font-size: 14px;
}

:deep() .el-select .el-input .el-input__inner {
  height: 32px;
  line-height: 32px;
}

.users-invitations-input {
  display: flex;
  &.multiple {
    :deep() .el-select__tags {
      flex-wrap: nowrap;
      max-width: 128px !important;
    }
  }
}

.loading-input {
  height: 100%;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  :deep() input {
    border: 1px solid $red-mayday !important;
  }
}

.delete-button {
  margin-top: 20px;
}

.select-category {
  padding: 0 20px;
}
.empty-category {
  padding: 0 20px;
  font-size: 14px;
  color: $grey-5-mayday;
}

:deep() .el-tag__close {
  color: $blue-mayday !important;
  border: 1px solid $blue-mayday !important;
  height: 16px;
  width: 16px;
  font-size: 12px;
  line-height: 1;
}

:deep() .el-tag__close:hover {
  color: white !important;
  background-color: $blue-mayday !important;
  border: 1px solid $blue-mayday !important;
}

.multiple-emails {
  margin-left: 16px;
  font-size: 14px;
  color: $grey-7-mayday;
}
.text-error {
  color: $red-mayday;
}
.button-wrapper {
  height: 32px;
}
</style>
