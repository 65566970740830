<template>
  <div class="new-knowledge-container">
    <div
      class="new-knowledge-header"
    >
      <router-link to="/settings/general#knowledges">
        <base-button
          variant="tertiary"
          size="md"
          :text="$t('settings.new-knowledge.back')"
          icon="chevron-left"
        ></base-button>
      </router-link>
    </div>
    <div class="new-knowledge-wrapper">
      <div v-loading="newKBLoading" class="new-knowledge-choose">
        <new-knowledge-choose-type
          :group-options="groups"
          :hostnameAlreadyExist="hostnameAlreadyExist"
          :step="step"
          :validityLoading="validityLoading"
          @new-kb-models="models = $event"
          @check-hostname="handleCheckHostname"
        ></new-knowledge-choose-type>
      </div>
    </div>

    <div
      :class="[
        'new-knowledge-footer d-flex',
        step === 1 ? 'justify-content-end' : 'justify-content-between',
      ]"
    >
      <base-button
        v-if="step > 1"
        variant="secondary"
        size="md"
        @click="handleBackClick"
        :text="$t('settings.new-knowledge.previous')"
      ></base-button>
      <base-button
        variant="primary"
        size="md"
        :text="$t('settings.new-knowledge.next')"
        @click="handleClick"
        :loading="newKBLoading"
        :disabled="
          (step === 2 && !(models.label || models.subdomain)) || newKBLoading
        "
      ></base-button>
    </div>
  </div>
</template>

<script>
import NewKnowledgeChooseType from './NewKnowledgeChooseType.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'new-knowledge',
  components: {
    NewKnowledgeChooseType,
  },
  data() {
    return {
      step: 1,
      models: {},
      newKBLoading: false,
      newConfig: {},
      hostnameAlreadyExist: false,
      validityLoading: false,
    };
  },
  computed: {
    ...mapGetters('publicKnowledgeModule', ['deploymentStatus']),
    ...mapGetters('adminModule', ['groups']),
  },
  methods: {
    handleBackClick() {
      if (this.step === 2) {
        this.models = {};
        this.step = 1;
      }
    },
    async handleClick() {
      if (this.step === 1) return (this.step = 2);
      if (this.models.subdomain) {
        this.newKBLoading = true;
        const newPublicConfig = await this.createCompanyPublicConfig({
          models: this.models,
        });
        this.newConfig = newPublicConfig;
        await this.getPendingDeploymentStatus(newPublicConfig.id);
      }
      if (this.models.label) {
        await this.addKnowledge({
          label: this.models.label,
          lang: this.models.lang,
          groups: this.models.groups,
        });
        const path = `/settings/general#knowledges`;
        this.$router.push({ path });
      }
    },
    async handleCheckHostname(hostname) {
      this.validityLoading = true;
      this.hostnameAlreadyExist = !(await this.checkHostname({
        hostname,
      }));
      this.validityLoading = false;
    },
    ...mapActions('publicKnowledgeModule', [
      'createCompanyPublicConfig',
      'checkHostname',
      'getPendingDeploymentStatus',
    ]),
    ...mapActions('adminModule', ['addKnowledge']),
  },
  watch: {
    async deploymentStatus() {
      if (
        this.deploymentStatus.deploymentStatus === 'STARTED' &&
        this.newConfig &&
        this.newConfig.knowledgeId
      ) {
        const path = `/public-settings/${this.newConfig.knowledgeId}`;
        this.$router.push({ path });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.new-knowledge-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
}

.new-knowledge-header {
  padding-left: 8px;
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
}

.new-knowledge-wrapper {
  flex: 1;
  width:100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-top: 8px;
}

.new-knowledge-choose {
  flex:1;
  overflow: hidden;
  background-color: white;
  padding: 24px;
  border-top-left-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: $shadow-mayday;
}

.new-knowledge-footer {
  padding: 10px;
}
</style>
