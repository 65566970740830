<template>
  <div class="sticky-top parameters-side-bar">
    <menu-collapse
      class="parameters-collapse"
      title="Paramètres"
      v-if="isParametric"
    >
      <h6 class="text-uppercase mb-0">Paramètres globaux</h6>
      <div v-for="family in content.enrichedParameters" :key="family.key">
        <el-divider class="my-1"></el-divider>
        <h6 class="row align-items-center mx-0 mb-0">
          <font-awesome-icon
            class="collapse-parameter-icon mr-1"
            :icon="['fad', getHierarchyByValue(family.key).icon]"
          />
          {{ getHierarchyByValue(family.key).label }}
        </h6>
        <div
          class="d-flex align-items-center"
          v-for="value in family.values"
          :key="value.id"
        >
          <small class="collapse-parameter-label" :key="value.id">{{
            value.label
          }}</small>
        </div>
      </div>
    </menu-collapse>

    <menu-collapse
      class="parameters-collapse"
      title="Informations"
      v-if="hasInfo && isParametric"
    >
      <div class="info-section">
        <h6
          class="text-uppercase mb-0"
          v-if="content.caseParameters.length > 0"
        >
          Collections
        </h6>
        <div
          class="d-flex align-items-center"
          v-for="(parsedCaseParameter, idx) in parsedCaseParameters"
          :key="idx"
        >
          <small class="collapse-info-label d-flex align-items-start">
            <font-awesome-icon
              class="collapse-info-icon mt-1 mr-1"
              :icon="['fad', 'folder-open']"
            />
            {{ parsedCaseParameter }}
          </small>
        </div>
      </div>
      <div class="info-section">
        <h6
          class="text-uppercase mb-0"
          v-if="content.accessRestrictions.length > 0"
        >
          Groupes
        </h6>
        <small
          class="collapse-info-label d-flex align-items-start"
          v-for="(accessRestriction, idx) in parsedAccessRestrictions"
          :key="idx"
        >
          <font-awesome-icon
            class="collapse-info-icon mt-1 mr-1"
            :icon="['fad', 'users']"
          />
          {{ accessRestriction }}
        </small>
      </div>
      <div class="info-section">
        <h6 class="text-uppercase mb-0" v-if="content.labels.length > 0">
          Labels
        </h6>
        <div class="d-flex">
          <ContentListItemLabel
            v-for="label in content.labels"
            :key="label.id"
            :label="label"
          />
        </div>
      </div>
    </menu-collapse>
    <SatisfactionRates
      class="sat-rate-component"
      :satisfactionRates="content.satisfactionRates"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import MenuCollapse from '@/components/Collapse/MenuCollapse';
import ContentListItemLabel from '@/components/ListItems/ContentListItemLabel';
import SatisfactionRates from './SatisfactionRates';
import ProductMixin from 'mixins/KnowledgeMixin/ProductMixin';

export default {
  name: 'parameter-side-bar',
  mixins: [ProductMixin],
  components: {
    MenuCollapse,
    ContentListItemLabel,
    SatisfactionRates,
  },
  data() {
    return {
      caseParameters: [],
      parsedCaseParameters: [],
    };
  },
  computed: {
    hasInfo() {
      return (
        this.content.caseParameters.length > 0 ||
        this.content.accessRestrictions.length ||
        this.content.labels.length > 0
      );
    },
    parsedAccessRestrictions() {
      return this.content.accessRestrictions.map((accessRestrictionId) => {
        const userGroup = this.userGroups.find(
          (group) => group.id === accessRestrictionId,
        );
        if (userGroup) {
          return userGroup.name;
        }
        return accessRestrictionId;
      });
    },
    ...mapState(['userGroups']),
    ...mapGetters(['isParametric']),
  },
  props: {
    content: Object,
  },
  async mounted() {
    const casePath = [
      ...this.content.caseParameters.flatMap((casePath) => casePath.split('/')),
    ];
    const caseIds = [];
    casePath.forEach((caseId) => {
      if (!caseIds.includes(caseId)) {
        caseIds.push(caseId);
      }
    });

    this.caseParameters = await this.$services.cases.getByIds(caseIds);
    this.parsedCaseParameters = this.content.caseParameters.map((casePath) => {
      return casePath
        .split('/')
        .map((c) => {
          const party = this.caseParameters.find((param) => param.id === c);
          return party.label;
        })
        .join('/');
    });
  },
  methods: {
    async loadData() {
      const casePath = [
        ...this.content.caseParameters.flatMap((casePath) =>
          casePath.split('/'),
        ),
      ];
      const caseIds = [];
      casePath.forEach((caseId) => {
        if (!caseIds.includes(caseId)) {
          caseIds.push(caseId);
        }
      });

      this.caseParameters = await this.$services.cases.getByIds(caseIds);
      this.parsedCaseParameters = this.content.caseParameters.map(
        (casePath) => {
          return casePath
            .split('/')
            .map((c) => {
              const party = this.caseParameters.find((param) => param.id === c);
              return party.label;
            })
            .join('/');
        },
      );
    },
    getHierarchyByValue(value) {
      const hierarchy = this.mxFlatHierarchy.find((h) => h.value === value);
      return hierarchy || { label: '', icon: 'books' };
    },
  },
  watch: {
    content: {
      handler: function () {
        this.loadData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.parameters-collapse {
  box-shadow: $shadow-lg-mayday;
  margin: 16px 0px;
}

.collapse-parameter-icon {
  max-width: 14px;
  max-height: 14px;
  margin: 8px 0px;
}

.collapse-parameter-label {
  color: $grey-9-mayday;
}

.collapse-info-label {
  word-break: break-word;
}

.collapse-info-icon {
  color: $grey-5-mayday;
}

.info-section {
  margin: 8px 0px;
}

.sat-rate-component {
}

.parameters-side-bar {
  top: 24px;
}
</style>
