<template>
  <div class="row justify-content-center template-viewer">
    <div class="mx-0 col-12">
      <div v-if="focusTemplate" v-loading="templateLoading" class="w-100">
        <TranslationBlur
          :blur="!languageIsSet && focusKnowledgeIsMultilinguale"
        />
        <KnowledgeEditorMainTitle
          class="row"
          :content="rootTemplate"
          :upper-title-text="
            focusTemplate.description ? focusTemplate.description : ''
          "
          :editable="true"
          :is-template="true"
          @update-content="updateName"
          @update-description="updateDescription"
          @open-set-language-modal="openSetLanguageModal"
          @unset-language="openUnsetLanguageModal($event)"
        />
        <div class="row content-editor">
          <div class="col-11 content-col">
            <ParametricArticleBuilder
              v-if="rootTemplate.type === 'Article'"
              :editable="true"
              :is-content-editable="true"
              :content="rootTemplate"
              :is-template="true"
              @update-written-content="debounceUpdateBody"
            />
            <ParametricDiagnosticBuilder
              v-else
              :editable="true"
              :is-content-editable="true"
              :root="rootTemplate"
              :step="templateStep"
              :template-id="focusTemplate.id"
              :options="childOptions"
              @focus-node="focusNode"
              @update-written-content="debounceUpdateBody"
              @update-content="updateContent"
              @delete-child="deleteDescendent"
              @add-child="createDescendent"
              @update-children-order="orderDescendents"
              @update-content-actions="handleUpdateTemplateActions"
              @delayed-update-content-actions="debounceUpdateTemplateActions"
            />
          </div>
        </div>
      </div>
    </div>
    <SetLanguageToast
      v-if="displaySetLanguageToast"
      class="toast-position"
      :language="editingLanguage"
      entity-type="template"
      @open-set-language-modal="openSetLanguageModal"
    />
    <SetLanguageModal
      v-if="displaySetLanguageModal"
      :display="setLanguageModal"
      :target-language="language"
      :allow-automatic-translation="false"
      @set-new-language="setNewLanguage"
      @close="
        setLanguageModal = false;
        language = '';
      "
    />
    <ModalEvents
      modal-name="DeleteContentModal"
      :uid="unsetLanguageModalUniqueKey"
      @choice="unsetLanguage"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import debounce from 'lodash.debounce';

// COMPONENTS
import ParametricArticleBuilder from '@/views/KnowledgeEditor/ParametricContentBuilder/ParametricArticleBuilder';
import ParametricDiagnosticBuilder from '@/views/KnowledgeEditor/ParametricContentBuilder/ParametricDiagnosticBuilder/ParametricDiagnosticBuilder';
import KnowledgeEditorMainTitle from '@/views/KnowledgeEditor/ParametricContentBuilder/ParametricContentMainTitle/KnowledgeEditorMainTitle';
import TranslationBlur from '@/components/TranslationBlur';
import SetLanguageToast from '@/components/Modals/SetLanguageToast';
import SetLanguageModal from '@/components/Modals/SetLanguageModal';
import ModalEvents from '@/components/Modals/ModalEvents';

import { appendChildren } from '@/store/knowledgeModule/utils';

export default {
  name: 'template-viewer',
  data() {
    return {
      templateLoading: false,
      focusContentId: null,
      childOptions: {
        redirection: false,
        relocate: true,
        delete: true,
        isStaged: false,
      },
      setLanguageModal: false,
      languageToUnset: '',
      language: '',
    };
  },
  components: {
    ParametricArticleBuilder,
    ParametricDiagnosticBuilder,
    KnowledgeEditorMainTitle,
    TranslationBlur,
    SetLanguageToast,
    SetLanguageModal,
    ModalEvents,
  },
  computed: {
    templateContent() {
      return {
        ...this.focusTemplate.content,
      };
    },
    templateDescendents() {
      return this.focusTemplate.descendents;
    },
    rootTemplate() {
      if (this.templateContent)
        return appendChildren(this.templateContent, this.templateDescendents);
      return {};
    },
    templateStep() {
      const content =
        !this.focusContentId || this.focusContentId === this.templateContent.id
          ? this.templateContent
          : this.templateDescendents.find(
              ({ id }) => this.focusContentId === id,
            );

      return appendChildren(content, this.templateDescendents);
    },
    displaySetLanguageToast() {
      return (
        this.focusKnowledgeIsMultilinguale &&
        !this.templateLoading &&
        !this.languageIsSet &&
        !this.language
      );
    },
    languageIsSet() {
      return (
        !this.templateStep ||
        !this.templateStep.setLanguages.length ||
        !!this.templateStep.setLanguages.filter(
          (lang) => lang === this.editingLanguage,
        ).length
      );
    },
    displaySetLanguageModal() {
      return this.focusKnowledgeIsMultilinguale && this.setLanguageModal;
    },
    unsetLanguageModalUniqueKey() {
      return `unset-template-language-${this.templateStep.id}`;
    },
    ...mapGetters('knowledgeModule', [
      'templates',
      'focusTemplate',
      'focusKnowledgeIsMultilinguale',
      'editingLanguage',
    ]),
  },
  async created() {
    await this.initTemplate();
  },
  methods: {
    async initTemplate() {
      this.$emit('change-route-type', 'Template');

      this.templateLoading = true;

      const templateId = this.$route.params.templateId;
      await this.getTemplate(templateId);
      this.focusContentId = this.templateContent.id;
      this.updateTemplatesPath([templateId]);

      this.templateLoading = false;
    },
    async focusNode(contentId) {
      const step = this.getFocusStep(contentId);
      if (step.redirection) {
        const { entityId, entityType } = step.redirection;

        const target = this.$route.path.includes('web-parametric')
          ? 'web-parametric'
          : 'knowledge';

        const path = await this.createRedirectUrl({
          entityId,
          entityType,
          target,
        });
        this.$router.push({
          path: path,
          query: {
            from: 'redirection',
          },
        });
      }
      this.focusContentId = contentId;
    },
    goToTemplates() {
      return this.$router.push('/knowledge/templates');
    },
    getFocusStep(contentId) {
      return !contentId || contentId === this.templateContent.id
        ? this.templateContent
        : this.templateDescendents.find(({ id }) => contentId === id);
    },
    debounceUpdateBody: debounce(function (e) {
      return this.updateBody(e);
    }, 1000),
    updateBody({ id, payload }) {
      this.updateTranslation({ id, payload }, 'body');
    },
    updateName({ id, payload }) {
      return this.updateTranslation({ id, payload }, 'label');
    },
    async updateTranslation({ id: descendentId, payload }, key) {
      if (payload[key]) {
        await this.updateTemplateTranslation({
          id: this.focusTemplate.id,
          descendentId,
          key,
          value: payload[key],
          lang: this.editingLanguage,
        });
      }
    },
    setNewLanguage(payload) {
      this.updateTemplateTranslation({
        id: this.focusTemplate.id,
        descendentId: this.templateStep.id,
        key: 'label',
        value: payload.label,
        lang: this.language,
      });
      this.setLanguageModal = false;
      this.language = '';
    },
    openUnsetLanguageModal(lang) {
      this.languageToUnset = lang;
      this.openModal({
        component: 'DeleteContentModal',
        uid: this.unsetLanguageModalUniqueKey,
        props: { display: true, lang, deleteType: 'language' },
      });
    },
    unsetLanguage(modalResp) {
      if (!modalResp) return;
      this.unsetTranslation({
        id: this.focusTemplate.id,
        descendentId: this.templateStep.id,
        lang: this.languageToUnset,
      });
    },
    updateContent({ id, payload }) {
      if (payload.label) {
        return this.updateTranslation({ id, payload }, 'label');
      }
    },
    async updateDescription(value) {
      await this.updateTemplate({
        id: this.focusTemplate.id,
        payload: { description: value },
      });
    },
    async deleteDescendent(descendentId) {
      const parentId = this.focusContentId;
      const payload = { id: this.focusTemplate.id, descendentId, parentId };
      await this.deleteTemplateDescendent(payload);
      this.focusNode(parentId);
    },
    async createDescendent({ label }) {
      const parentId = this.focusContentId;
      const { ancestors } = this.templateStep;
      const payload = { id: this.focusTemplate.id, parentId, ancestors, label };
      await this.createTemplateDescendent(payload);
      this.focusNode(parentId);
    },
    async orderDescendents({ id: descendentId, payload }) {
      await this.orderTemplateDescendents({
        id: this.focusTemplate.id,
        descendentId,
        path: payload.path,
      });
      this.$root.$emit('UPDATE_IS_LOADING', false);
      this.focusNode(descendentId);
    },
    openSetLanguageModal(lang) {
      this.setLanguageModal = true;
      this.language = lang || this.editingLanguage;
    },
    debounceUpdateTemplateActions: debounce(function (e) {
      return this.handleUpdateTemplateActions(e);
    }, 1000),
    async handleUpdateTemplateActions(actions) {
      await this.updateTemplateActions({
        id: this.focusTemplate.id,
        descendentId: this.templateStep.id,
        actions,
      });
    },
    ...mapActions('knowledgeModule', [
      'getTemplate',
      'getTemplates',
      'updateTemplate',
      'updateTemplateTranslation',
      'deleteTemplateDescendent',
      'createTemplateDescendent',
      'orderTemplateDescendents',
      'updateTemplatesPath',
      'updateTemplateActions',
      'unsetTranslation',
    ]),
    ...mapActions(['createRedirectUrl']),
    ...mapActions('modalsModule', ['openModal']),
  },
  watch: {
    $route() {
      if (this.$route.params.templateId !== this.focusTemplate.id)
        return this.initTemplate();
    },
    async editingLanguage() {
      this.templateLoading = true;

      const templateId = this.$route.params.templateId;
      await this.getTemplate(templateId);
      this.getTemplates();

      this.templateLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.template-viewer {
  height: calc(100vh - 70px);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
  box-shadow: $shadow-mayday;
}

.toast-position {
  top: 45px;
  right: 12px;
}
</style>
