<template>
  <div
    class="dashboard-container"
    v-if="isLoggedIn"
    v-loading="!filterValuesArePopulated"
  >
    <div class="dashboard-header">
      <div
        v-if="tabRoutes && tabRoutes.length"
        class="dashboard-tabs-wrapper"
      >
        <router-link
          v-for="route in tabRoutes"
          :key="route.name"
          :to="`${parentRoutePath}/${route.path}`"
        >
          <base-link
            :icon="route.meta.icon"
            :text="$t(`new-dashboard.tab-name.${route.meta.tabName}`)"
            :underline="false"
            :variant="activeLink === `${parentRoutePath}/${route.path}` ? 'primary' : 'secondary'"
            icon-placement="left"
          />
        </router-link>
      </div>
      <base-button
        :text="$t('new-dashboard.open-filters-label')"
        class="filters-open-link"
        icon="sliders-v"
        size="md"
        variant="tertiary"
        @click="openFiltersDrawer"
      />
    </div>
    <div class="dashboard-router-wrapper">
      <div class="dashboard-router">
        <router-view
          :external-doc-url="externalDocUrl"
          @open-filters-drawer="openFiltersDrawerFromChild"
        ></router-view>
      </div>
    </div>
    <filters-drawer
      :show.sync="showFiltersDrawer"
      :filters="filters"
      :values="filterValues"
      :additional-values="filterAdditionalValues"
      :daterange-shortcut-values="filterDateRangeShortcutValues"
      :filters-are-ready="filtersAreReady"
      @change="updateFilterValue"
      @change-additional="updateAdditionalFilterValue"
      @reset="resetFilterValue"
      @close="closeFiltersDrawer"
    />
  </div>
</template>

<script>
import FiltersDrawer from '../../components/Filters/Drawer';
import {
  createNamespacedHelpers,
  mapState,
  mapGetters as mapVueXGetters,
} from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('dashboard');
import { EXTERNAL_DOC_URL } from '@/store/dashboard/constants';

export default {
  name: 'new-dashboard',

  components: {
    FiltersDrawer,
  },

  data() {
    return {
      showFiltersDrawer: false,
      activeLink: null,
      tabRoutes: [],
      parentRoutePath: '/dashboard',
      externalDocUrl: EXTERNAL_DOC_URL,
      publicPaths: ['analytics-contents', 'analytics-ask'],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.activeLink = to.path;
      vm.updateActiveTab(to.name.replace('analytics-', ''));
    });
  },

  beforeRouteUpdate(to, from, next) {
    if (to.path !== '/login') {
      this.activeLink = to.path;
      this.updateActiveTab(to.name.replace('analytics-', ''));
    }
    next();
  },

  beforeRouteLeave(to, from, next) {
    this.activeLink = null;
    this.updateActiveTab(null);
    next();
  },

  created() {
    this.tabRoutes = this.extractRoutes();
    this.prepareFilters();
  },

  computed: {
    ...mapGetters([
      'filterValuesArePopulated',
      'filterDateRangeShortcutValues',
      'filtersAreReady',
      'filters',
      'filterValues',
      'filterAdditionalValues',
      'tableFile',
      'isPublicSelected',
    ]),
    ...mapVueXGetters(['hasCompanyPreferenceWithValue', 'hasPermission']),
    ...mapState(['companyPreferences']),
    isLoggedIn() {
      return this.$store.getters.loggedIn;
    },
  },

  methods: {
    ...mapActions([
      'prepareFilters',
      'updateFilterValue',
      'updateAdditionalFilterValue',
      'resetFilterValue',
      'resetTableFile',
      'updateActiveTab',
    ]),

    closeFiltersDrawer() {
      if (this.showFiltersDrawer) {
        this.showFiltersDrawer = false;
      }
    },

    openFiltersDrawerFromChild() {
      if (!this.showFiltersDrawer) {
        this.showFiltersDrawer = true;
      }
    },

    extractRoutes() {
      const routes = this.$router.options.routes
        .find(({ path, redirect }) => redirect === '/home-v2' && path === '/')
        .children.find(({ path }) => path === this.parentRoutePath)
        // remove default redirect route
        .children.filter(({ path }) => {
          const isAllowedForPublic = this.isPublicSelected
            ? path !== '' && this.publicPaths.includes(path)
            : path !== '';
          if (!isAllowedForPublic) return false;
          if (path === 'analytics-ask') {
            return this.hasCompanyPreferenceWithValue('BRAIN_ENABLE_ASK');
          }
          if (path === 'analytics-workflow') {
            return this.hasCompanyPreferenceWithValue('WORKFLOW_ACTIVATED');
          }
          if (path === 'analytics-workflow-session') {
            return this.hasCompanyPreferenceWithValue('WORKFLOW_ACTIVATED');
          }
          if (path === 'analytics-download-table') {
            return this.hasPermission('READ_ANALYTICS_EXPORTS');
          }
          return true;
        });

      return routes;
    },

    openFiltersDrawer(event) {
      event.preventDefault();
      this.showFiltersDrawer = true;
    },
  },

  watch: {
    tableFile(value) {
      if (value == null) return;
      const currentDate = new Date();
      const file = new File(
        [value],
        `${currentDate.getFullYear()}_${
          currentDate.getMonth() + 1
        }_${currentDate.getDate()}__mayday_export.xlsx`,
      );

      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = URL.createObjectURL(file);
      link.download = file.name;

      // It needs to be added to the DOM so it can be clicked
      document.body.appendChild(link);
      link.click();

      // To make this work on Firefox we need to wait
      // a little while before removing it.
      setTimeout(() => {
        URL.revokeObjectURL(link.href);
        link.parentNode.removeChild(link);
      }, 0);
      this.resetTableFile();
    },
    isPublicSelected() {
      this.tabRoutes = this.extractRoutes();
      if (
        this.isPublicSelected &&
        this.$router.currentRoute.path !== 'analytics-contents'
      ) {
        this.$router.push({ path: 'analytics-contents' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dashboard-container {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 32px;
  height: 68px;
}

.dashboard-tabs-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
  flex: none;

  :not(:first-child) {
    margin-left: 30px;
  }
}

.dashboard-router-wrapper {
  padding-left: 8px;
  padding-top: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

}

.dashboard-router {
  background-color: white;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
  box-shadow: $shadow-mayday;
  border-top-left-radius: 16px;
}
</style>
