<template>
  <div
    class="d-flex flex-row align-items-center private-header sticky-top justify-content-between"
  >
    <div class="d-flex flex-row align-items-center select-wrapper">
      <el-tooltip
        :content="$t('public-knowledge.deployment.return')"
        @mouseenter.native="back = true"
        @mouseleave.native="back = false"
      >
        <router-link
          class="icon-wrapper mr-2"
          to="/settings/general#knowledges"
        >
          <base-button
            variant="tertiary"
            size="md"
            icon="angle-left"
            :icon-only="true"
          ></base-button>
        </router-link>
      </el-tooltip>
      <el-divider direction="vertical" class="menu-divider mr-3"></el-divider>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          {{ focusAdminKnowledge.label }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <router-link
            v-for="knowledge in privateAdminKnowledges"
            :key="knowledge.id"
            class="dropdown-links"
            :to="{
              name: 'private-settings',
              params: {
                knowledgeId: knowledge.id,
              },
            }"
          >
            <el-dropdown-item>
              {{ knowledge.label }}
            </el-dropdown-item>
          </router-link>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'private-knowledge-header',
  props: {},
  data() {
    return {
      back: false,
    };
  },
  computed: {
    ...mapGetters('adminModule', [
      'privateAdminKnowledges',
      'focusAdminKnowledge',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.private-header {
  background-color: white;
  padding: 15px;
  border-bottom: 2px solid $grey-4-mayday;
  max-width: 100%;
  min-width: calc(100% - 60px);
  max-height: 60px;
  position: fixed;
  z-index: 10 !important;
}

.select-wrapper {
  min-width: fit-content;
  width: 250px;
}

.icon-wrapper {
  width: 32px;
  height: 32px;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon {
  width: 14px;
  height: 16px;
  color: $blue-mayday;
}

.menu-divider {
  height: 34px;
}

.el-dropdown-link:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.dropdown-links {
  color: $grey-7-mayday;
}

:deep() .dropdown-links:hover {
  .el-dropdown-menu__item {
    color: $blue-mayday !important;
  }
}

:deep() .router-link-active {
  .el-dropdown-menu__item {
    color: $blue-mayday !important;
  }
}
</style>
